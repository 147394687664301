import omit from "lodash-es/omit";

import { commentRegex } from "@ll-web/features/projectComments/consts";
import {
  type ParsedCommentAnchor,
  type ParsedCommentsAnchorsMap,
  type ProjectComment,
} from "@ll-web/features/projectComments/types";
import { assertDefined, typedEntries } from "@ll-web/utils/types/types";

const getArrayOfComments = <T>(output: T): string[] => {
  const result: string[] = [];

  const traverse = (value: T) => {
    if (typeof value === "string") {
      let match;
      while ((match = commentRegex.exec(value)) !== null) {
        result.push(match[0]);
      }
    } else if (Array.isArray(value)) {
      for (const item of value) {
        traverse(item);
      }
    } else if (typeof value === "object" && value !== null) {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          traverse((value as Record<string, T>)[key]);
        }
      }
    }
  };

  traverse(output);

  return result;
};

const parseCommentsMetadata = (commentString: string) => {
  const regex = /^\[(.*?)\]\(comment '(.*?)'\)$/;
  const match = regex.exec(commentString);

  if (match) {
    const quote = match[1];
    const jsonString = match[2];
    try {
      const metadata: ParsedCommentAnchor = JSON.parse(jsonString);
      assertDefined(metadata.id);

      return {
        [metadata.id]: {
          ...metadata,
          fullCommentString: commentString,
          quote,
        },
      };
    } catch (error) {
      if (import.meta.env.PROD || !(error instanceof Error)) {
        throw error;
      }

      throw new Error(`Error parsing JSON for comment: ${commentString}`, {
        cause: error,
      });
    }
  } else {
    throw new Error(`No metadata found for comment: ${commentString}`);
  }
};

const mapCommentsWithMetadata = (
  commentStrings: string[],
): ParsedCommentsAnchorsMap => {
  const acc: ParsedCommentsAnchorsMap = {};

  commentStrings.forEach((commentString) => {
    const parsedComment = parseCommentsMetadata(commentString);

    Object.assign(acc, parsedComment);
  });

  return acc;
};

export const parsedCommentAnchorsMap = <T>(
  output: T,
): ParsedCommentsAnchorsMap => {
  const arrayOfComments = getArrayOfComments(output);

  const commentsMap = mapCommentsWithMetadata(arrayOfComments);

  return commentsMap;
};

const createCommentTextAnchor = (comment: ParsedCommentAnchor): string => {
  const data = omit(comment, [
    "fullCommentString",
    "updatedFullCommentString",
    "quote",
  ]);
  const commentJsonPart = JSON.stringify(data);

  const markdownString = `[${comment.quote}](comment '${commentJsonPart.replaceAll('"', '\\"')}')`;

  return markdownString;
};

const syncCommentAnchorWithProjectComment = ({
  textComment,
  projectComment,
}: {
  textComment: ParsedCommentAnchor;
  projectComment?: ProjectComment;
}) => {
  if (projectComment?.isDeleted && textComment) {
    return {
      ...textComment,
      updatedFullCommentString: textComment.quote,
    };
  }
  if (projectComment && projectComment.isResolved !== textComment.isResolved) {
    return {
      ...textComment,
      updatedFullCommentString: createCommentTextAnchor({
        ...textComment,
        isResolved: projectComment.isResolved,
      }),
    };
  }

  return textComment;
};

export const commentsAnchorsSyncedWithProjectComments = ({
  textCommentsMap,
  projectComments,
}: {
  textCommentsMap: ParsedCommentsAnchorsMap;
  projectComments: ProjectComment[];
}) => {
  return Object.fromEntries(
    typedEntries(textCommentsMap).map(([commentId, textComment]) => {
      const projectComment = projectComments.find(
        (comment) => comment.id === commentId,
      );

      return [
        commentId,
        syncCommentAnchorWithProjectComment({ textComment, projectComment }),
      ];
    }),
  );
};
