import { ProjectFormStepper } from "@ll-web/features/internalProjects/pages/ProjectForm/components/stepper/ProjectFormStepper";
import { useProjectFormRouteNavigation } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/hooks/useProjectFormRouteNavigation";

export const ProjectFormRouteStepper = () => {
  const { steps, currentStepIndex, goToStep, stepperSteps } =
    useProjectFormRouteNavigation();

  return (
    <ProjectFormStepper
      steps={steps}
      currentStepIndex={currentStepIndex}
      goToStep={goToStep}
      stepperSteps={stepperSteps}
    />
  );
};
