/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

/**
 * WARN: DAYJS TIMEZONE IS BUGGY
 * https://github.com/iamkun/dayjs/issues/1805
 *
 * If you perform any date operations while in tz mode,
 * we must reapply the timezone using `.tz("timezone here", true)`
 * before converting to ISO string, native Date or timestamp
 */

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

// expose dayjs to play around in devtools
// @ts-expect-error
window.dayjs = dayjs;
