import {
  createBrowserRouter,
  Navigate,
  type RouteObject,
} from "react-router-dom";

import { BaseLayout } from "@ll-web/components/layouts/BaseLayout";
import { ErrorPage } from "@ll-web/components/statusPages/ErrorPage";
import { APP_CONFIG, AppEnvEnum } from "@ll-web/config/app.config";
import { lazyLoad, withLoader } from "@ll-web/core/router/helpers";
import { creatorRoutes } from "@ll-web/creator/router";
import { AuthRedirectController } from "@ll-web/features/auth/pages/AuthRedirectController";
import { appRoutes } from "@ll-web/features/router";

const Status404 = withLoader(
  lazyLoad(() => import("@ll-web/components/statusPages/Status404")).default,
);

const GoogleOAuthCallbackController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/container/auth/pages/callbacks/google/GoogleOAuthCallbackController"
      ),
  ).GoogleOAuthCallbackController,
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    ErrorBoundary: [AppEnvEnum.Production, AppEnvEnum.Staging].includes(
      APP_CONFIG.REACT_APP_ENV,
    )
      ? ErrorPage
      : undefined,
    children: [
      { path: "", element: <AuthRedirectController /> },
      ...appRoutes,
      ...creatorRoutes,
      {
        path: "auth",
        children: [
          {
            path: "google",
            children: [
              {
                path: "callback",
                element: <GoogleOAuthCallbackController />,
              },
            ],
          },
        ],
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export const mainRouter = createBrowserRouter(routes);
