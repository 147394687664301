import { useGetProposalById } from "@ll-web/features/proposals/async/useProposalsQueries";
import type { ProposalWithBrand } from "@ll-web/features/proposals/types";

export const useProposal = (id: string) => {
  const query = useGetProposalById<ProposalWithBrand>({
    id,
  });

  if (!query.isSuccess) {
    throw new Error(
      `Active Proposal is pending, missing active project id or guard in '${window.location.pathname}'`,
      {
        cause: query.error,
      },
    );
  }

  return { activeProposal: query.data };
};
