import { ActivityType } from "@ll-web/core/analytics/events";
import { AccountType, type User } from "@ll-web/core/auth/types";
import { type BrandUserWithProfile } from "@ll-web/features/brands/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { wizardGenerateExternalEmail } from "@ll-web/features/projectWizard/reviews/emailTemplates/wizardGenerateExternalEmail";
import { wizardGenerateExternalLegacyEmail } from "@ll-web/features/projectWizard/reviews/emailTemplates/wizardGenerateExternalLegacyEmail";
import { ReviewModalTitle } from "@ll-web/features/reviews/consts";
import type { ReviewModalTemplate } from "@ll-web/features/reviews/types";
import { getReviewEmailUsers } from "@ll-web/features/reviews/utils/getReviewEmailUsers";

import { reviewWizardAnalyticsMetadata } from "./common/reviewWizardAnalyticsMetadata";
import { reviewWizardToastParams } from "./common/reviewWizardTexts";

type WizardGenerateExternalModalTemplateArgs = {
  users: BrandUserWithProfile[];
  activeUser: User;
  brandName?: string;
  project?: ProjectWithDeliverables;
  page: string;
  isLegacyFlow?: boolean;
};

export const wizardGenerateExternalModalTemplate = ({
  users,
  project,
  activeUser,
  page,
  isLegacyFlow,
}: WizardGenerateExternalModalTemplateArgs): ReviewModalTemplate => {
  const emailDataCallback = isLegacyFlow
    ? wizardGenerateExternalLegacyEmail
    : wizardGenerateExternalEmail;

  return {
    reviewEmailData: emailDataCallback({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: isLegacyFlow
          ? [AccountType.Producer, AccountType.Creative]
          : [AccountType.Producer],
      }),
      activeUser,
      project,
    }),
    titleContent: ReviewModalTitle.notifyProducerForReview,
    headingContent: "",
    toastParams: reviewWizardToastParams,
    analyticsMetadata: reviewWizardAnalyticsMetadata({
      submitType: ActivityType.CreativeBriefClickedSubmitForReview,
      cancelType: ActivityType.CreativeBriefCanceledSubmittingForReview,
      activeUser,
      page,
      project,
    }),
  };
};
