import {
  ScriptedStoryboardVisualStyleEnum,
  type AnimationStyleEnum,
  type ProjectStyleEnum,
} from "./consts";

/*
NOTE:
All new feature flags should go to LaunchDarkly

When adding feature flags to LaunchDarkly,
remember to enable "Client-Side SDK Availability"
*/

export enum FeatureFlagName {
  GoogleLogin = "googleLogin",
  OpenaiDefaultModel = "openaiDefaultModel",
  IntroVideoLink = "introVideoLink",
  CloneProject = "cloneProject",
  TalentBadMatchThreshold = "talentBadMatchThreshold",
  TalentBestMatchThreshold = "talentBestMatchThreshold",
  AnimationStyleSelectionVideos = "animationStyleSelectionVideos",
  ScriptedStoryboardVisualStyles = "scriptedStoryboardVisualStyles",
  ProjectSpace = "projectSpace",
  ProjectTimeline = "projectTimeline",
  ProjectSpaceFinalizedProjectView = "projectSpaceFinalizedProjectView",
  ProjectSpaceCreativeDeck = "projectSpaceCreativeDeck",
  PostProductionMediaManagement = "postProductionMediaManagement",
  NewWizardFlow = "newWizardFlow",
  TtsStabilityControl = "ttsStabilityControl",
  TtsSimilarityControl = "ttsSimilarityControl",
  TtsStyleControl = "ttsStyleControl",
  CrewSpace = "crewSpace",
  // After removing this feature flag,
  // please update the launchdarkly organization context
  Hero = "hero",
  CreativeProposals = "creativeProposals",
  ProposalProcessDefaultImages = "proposalProcessDefaultImages",
  ProposalDefaultImages = "proposalDefaultImages",
  CreativeProposalBudget = "creativeProposalBudget",
}

/* Default values should be:
  - Disabled for feature flags
  - Enabled for kill-switches
  - Reasonably safe and tested values for others
  - If unknown or the value is too long to keep in this file,
    you can provide a dummy value with `as` assertion
*/
export const defaultFeatureFlags = {
  [FeatureFlagName.GoogleLogin]: true,
  [FeatureFlagName.OpenaiDefaultModel]: "gpt-4o",
  [FeatureFlagName.IntroVideoLink]: {} as Partial<
    Record<ProjectStyleEnum, string>
  >,
  [FeatureFlagName.AnimationStyleSelectionVideos]: {} as Partial<
    Record<AnimationStyleEnum, string>
  >,
  [FeatureFlagName.CloneProject]: false,
  [FeatureFlagName.TalentBadMatchThreshold]: 0.5,
  [FeatureFlagName.TalentBestMatchThreshold]: 0.5,
  [FeatureFlagName.ScriptedStoryboardVisualStyles]: [
    ScriptedStoryboardVisualStyleEnum.Photorealistic,
    ScriptedStoryboardVisualStyleEnum.SketchStyle,
    ScriptedStoryboardVisualStyleEnum.Illustrated,
  ],
  [FeatureFlagName.ProjectSpace]: false,
  [FeatureFlagName.ProjectTimeline]: false,
  [FeatureFlagName.ProjectSpaceFinalizedProjectView]: false,
  [FeatureFlagName.ProjectSpaceCreativeDeck]: false,
  [FeatureFlagName.PostProductionMediaManagement]: false,
  [FeatureFlagName.NewWizardFlow]: false,
  [FeatureFlagName.TtsStabilityControl]: false,
  [FeatureFlagName.TtsSimilarityControl]: false,
  [FeatureFlagName.TtsStyleControl]: false,
  [FeatureFlagName.CrewSpace]: false,
  [FeatureFlagName.Hero]: false,
  [FeatureFlagName.CreativeProposals]: false,
  [FeatureFlagName.ProposalProcessDefaultImages]: {} as Partial<{
    header: string;
    preproduction: string[];
    postproduction: string[];
  }>,
  [FeatureFlagName.ProposalDefaultImages]: {} as Partial<{
    header: string;
    footer: string;
  }>,
  [FeatureFlagName.CreativeProposalBudget]: false,
} satisfies Record<FeatureFlagName, unknown>;

export type FeatureFlags = typeof defaultFeatureFlags;
