import { useCallback, useMemo } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OnboardingSteps } from "@ll-web/core/auth/types";
import { Pages } from "@ll-web/core/router/pages";
import { getNextParam, makeNextParam } from "@ll-web/utils/helpers/navigation";

export const useOnboardNavigation = () => {
  const [searchParams] = useSearchParams();
  const next = getNextParam(searchParams);
  const { step } = useParams<{
    step: OnboardingSteps;
  }>();

  const currentStep = useMemo(() => {
    return step ?? OnboardingSteps.UserInformation;
  }, [step]);

  const navigate = useNavigate();
  const makeAbsolutePathForStep = useCallback(
    (step: OnboardingSteps) => {
      const basePath = `${Pages.BrandOnboarding}/${step}`;

      return `${basePath}${next ? `?${makeNextParam(next)}` : ""}`;
    },
    [next],
  );

  const currentStepIndex = useMemo(() => {
    return Object.values(OnboardingSteps).findIndex(
      (step) => step === currentStep,
    );
  }, [currentStep]);

  const nextStep = useMemo(() => {
    return Object.values(OnboardingSteps)[currentStepIndex + 1];
  }, [currentStepIndex]);

  const goNext = useCallback(() => {
    if (nextStep) {
      navigate(makeAbsolutePathForStep(nextStep));
    } else {
      navigate(next ?? "/");
    }
  }, [nextStep, makeAbsolutePathForStep, navigate, next]);

  return {
    makeAbsolutePathForStep,
    currentStep,
    next,
    goNext,
  };
};
