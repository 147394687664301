export const REVIEW_FORM_BLOCKING_PHRASES = {
  hubspotLink: {
    text: "[ADD YOUR HUBSPOT LINK]",
    errorMessage:
      "Please replace the placeholder [ADD YOUR HUBSPOT LINK] with a valid link or remove it before submitting.",
  },
  date: {
    text: "[DATE]",
    errorMessage:
      "Please replace the placeholder [DATE] with a valid date or remove the text before submitting.",
  },
} as const;

export enum ReviewModalTitle {
  notifyProducerForReview = "Notify Producer for Review",
  notifyClientForReview = "Notify Client for Review",
  submitForReview = "Submit for Review",
  approveForClient = "Approve for Client",
  notifyForInternalReview = "Notify for Internal Review",
  shareProposalForInternalReview = "Share Creative Proposal for Internal Review",
  shareCreativeProposalToClient = "Share Creative Proposal to Client",
}

export const reviewDefaultHeading =
  "You're about to submit for review. Please confirm that all necessary edits have been made.";
