import dayjs from "dayjs";

import {
  ORGANIZATION_BACKDOOR_LS_KEY,
  ORGANIZATION_ID_LS_KEY,
} from "@ll-web/consts/organization";
import type { Organization } from "@ll-web/core/organization/types";

class OrganizationService {
  getOrganizationId(): string | null {
    return localStorage.getItem(ORGANIZATION_ID_LS_KEY) ?? null;
  }

  setOrganizationId(organizationId: string | null): void {
    if (!organizationId) {
      localStorage.removeItem(ORGANIZATION_ID_LS_KEY);
    } else {
      localStorage.setItem(ORGANIZATION_ID_LS_KEY, organizationId);
    }
  }

  setBackdoorOrganizationId(organizationId: string | null): void {
    this.setOrganizationId(organizationId);
    if (!organizationId) {
      localStorage.removeItem(ORGANIZATION_BACKDOOR_LS_KEY);
    } else {
      localStorage.setItem(ORGANIZATION_BACKDOOR_LS_KEY, true.toString());
    }
  }

  isBackdoorOrganization(): boolean {
    return (
      localStorage.getItem(ORGANIZATION_BACKDOOR_LS_KEY) === "true" &&
      !!this.getOrganizationId()
    );
  }

  makeFakeOrganization(organizationId: string): Organization {
    return {
      id: organizationId,
      name: `Unknown ${organizationId.slice(-6)}`,
      createdAt: dayjs(),
      updatedAt: dayjs(),
    };
  }

  promptForBackdoorOrganizationId(): void {
    const organizationId = prompt("Enter organization ID");
    this.setBackdoorOrganizationId(organizationId);
    window.location.reload();
  }
}

export const organizationService = new OrganizationService();
