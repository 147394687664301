import { noop } from "lodash-es";

import { MAX_BACKEND_TIMEOUT } from "@ll-web/core/api/consts";
import { heroHttpClient } from "@ll-web/core/api/HeroHttpClient";
import type {
  CreateEvent,
  CreateUpdateProfile,
} from "@ll-web/core/klaviyo/types";

class KlaviyoService {
  async makeCreateProfile(args: CreateUpdateProfile) {
    return await heroHttpClient.unwrappedHttpRequest<CreateUpdateProfile>({
      config: {
        method: "POST",
        url: `/v1/klaviyo/profile-import`,
        data: args,
      },
      handleUnauthorized: noop,
    });
  }

  async makeCreateEvent(args: CreateEvent) {
    return await heroHttpClient.unwrappedHttpRequest<CreateEvent>({
      config: {
        method: "POST",
        url: `/v1/klaviyo/events`,
        data: args,
        timeout: MAX_BACKEND_TIMEOUT,
      },
      handleUnauthorized: noop,
    });
  }
}

export const klaviyoService = new KlaviyoService();
