import { useMemo } from "react";

import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  CustomStepper,
  type StepperStep,
} from "@ll-web/components/CustomStepper/CustomStepper";
import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { BudgetSummary } from "@ll-web/features/budget/components/BudgetSummary/BudgetSummary";
import type { ProjectFormSteps } from "@ll-web/features/internalProjects/pages/ProjectForm/consts";
import { useProjectEmpty } from "@ll-web/features/internalProjects/pages/ProjectForm/hooks/useProjectFormEmpty";
import type { ProjectFormValues } from "@ll-web/features/internalProjects/projectForm/projectFormSchema";
import { mapProjectFormToDto } from "@ll-web/features/internalProjects/projectForm/utils/mapFormToDto";

type ProjectFormStepper = {
  steps: ProjectFormSteps[];
  currentStepIndex: number;
  goToStep: (step: ProjectFormSteps) => void;
  stepperSteps: StepperStep<ProjectFormSteps>[];
};

export const ProjectFormStepper = ({
  steps,
  currentStepIndex,
  goToStep,
  stepperSteps,
}: ProjectFormStepper) => {
  const flags = useAppFlags();
  const isEmpty = useProjectEmpty();
  const stepIndex = isEmpty ? 0 : currentStepIndex;
  const activeStep = steps[stepIndex];

  const { watch } = useFormContext<ProjectFormValues>();
  const proposalId = watch("basicInfo.proposal.proposalId");
  const formValues = watch();
  const project = useMemo(() => {
    return {
      id: formValues.id,
      ...mapProjectFormToDto(formValues),
    };
  }, [formValues]);

  return (
    <Stack
      sx={
        proposalId
          ? {
              flexGrow: 1,
              justifyContent: "space-between",
            }
          : {}
      }
    >
      <CustomStepper
        steps={stepperSteps}
        activeStep={activeStep}
        onClick={(step) => goToStep(step.key)}
        variant="light"
      />
      {flags[FeatureFlagName.CreativeProposalBudget] && proposalId && (
        <BudgetSummary project={project} isInProjectForm />
      )}
    </Stack>
  );
};
