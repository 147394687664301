import { useCallback, useEffect, useMemo, type PropsWithChildren } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  LDProvider,
  useFlags,
  useLDClient,
  type LDContext,
  type LDOptions,
  type LDReactOptions,
} from "launchdarkly-react-client-sdk";

import { APP_CONFIG } from "@ll-web/config/app.config";
import {
  FEATURE_FLAGS_QUERY_KEY,
  FeatureFlagsProvider,
} from "@ll-web/config/featureFlags/consts";
import type { FeatureFlags } from "@ll-web/config/featureFlags/featureFlags";
import { useAnyUser } from "@ll-web/core/hooks/useAnyUser";
import { organizationService } from "@ll-web/core/organization/async/OrganizationsService";
import { assertDefined } from "@ll-web/utils/types/types";

const options: LDOptions = {
  diagnosticOptOut: true,
  // We don't need any events for now
  sendEventsOnlyForVariation: true,
  // We don't support updated to flags once the app is mounted except for active user updates
  streaming: false,
  application: {
    id: APP_CONFIG.REACT_APP_ENV,
    version: APP_CONFIG.REACT_APP_VERSION,
  },
};

const reactOptions: LDReactOptions = {
  sendEventsOnFlagRead: false,
  useCamelCaseFlagKeys: false,
};

const clientSideId = APP_CONFIG.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

function useLdContext() {
  const activeUser = useAnyUser();
  const context = useMemo<LDContext>(() => {
    if (!activeUser) {
      return {
        kind: "user",
        key: "anonymous",
        anonymous: true,
      };
    }

    // Can't use the useActiveOrganization hook as it depends on the flags
    const organizationId = organizationService.getOrganizationId();

    return {
      kind: "multi",
      user: {
        kind: "user",
        key: activeUser.id,
        email: activeUser.email,
        firstName: activeUser.firstName,
        lastName: activeUser.lastName,
        accountType: activeUser.accountType,
      },
      ...(organizationId && {
        organization: {
          kind: "organization",
          key: organizationId,
        },
      }),
    };
  }, [activeUser]);

  return context;
}

const LaunchDarklyInnerContextUpdater = () => {
  const ldClient = useLDClient();
  const ldIdentifyContext = useLdContext();

  useEffect(() => {
    ldClient?.identify(ldIdentifyContext);
  }, [ldClient, ldIdentifyContext]);

  return null;
};

export const LaunchDarklyProvider = ({ children }: PropsWithChildren) => {
  const ldContext = useLdContext();

  if (!clientSideId) {
    return children;
  }

  return (
    <LDProvider
      clientSideID={clientSideId}
      timeout={2}
      context={ldContext}
      reactOptions={reactOptions}
      options={options}
    >
      <LaunchDarklyInnerContextUpdater />
      {children}
    </LDProvider>
  );
};

export function useLaunchDarklyFeatureFlags() {
  const queryClient = useQueryClient();
  const ldClient = useLDClient();

  // We can't use the standard async LD loader because
  // 1. We need the app to be rendered while waiting for flags
  // 2. We integrate multiple providers
  const waitForInitialization = useCallback(async () => {
    assertDefined(ldClient, "ldClient");
    await ldClient.waitForInitialization(2);
    const flags = ldClient.allFlags() as Partial<FeatureFlags>;

    return flags;
  }, [ldClient]);

  const query = useQuery({
    // active user is not needed in query key as it is automatically updated with useEffect
    queryKey: [FEATURE_FLAGS_QUERY_KEY, FeatureFlagsProvider.LaunchDarkly],
    queryFn: waitForInitialization,
    retry: 1,
    gcTime: Infinity,
    staleTime: Infinity,
    meta: {
      supressErrorToast: true,
    },
    enabled: !!ldClient,
  });

  const flags = useFlags() as Partial<FeatureFlags>;

  useEffect(() => {
    if (!query.isSuccess) {
      return;
    }

    queryClient.setQueryData(
      [FEATURE_FLAGS_QUERY_KEY, FeatureFlagsProvider.LaunchDarkly],
      flags,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags]);

  useEffect(() => {
    if (!clientSideId) {
      queryClient.setQueryData(
        [FEATURE_FLAGS_QUERY_KEY, FeatureFlagsProvider.LaunchDarkly],
        {},
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return query;
}
