import { useCallback, useMemo, useState } from "react";

import { toast } from "react-toastify";

import type { ProposalBudgetDiscount } from "@ll-web/features/budget/types";

import { BudgetDiscountDialog } from "./BudgetDiscountDialog";

export type UseBudgetDiscountDialogArgs = {
  discount?: ProposalBudgetDiscount;
  maxDiscountValue?: number;
  onSubmit: (args: { data: ProposalBudgetDiscount }) => Promise<void>;
  onRemove: () => Promise<void>;
};

export const useBudgetDiscountDialog = ({
  discount,
  maxDiscountValue,
  onSubmit,
  onRemove,
}: UseBudgetDiscountDialogArgs) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSubmit = useCallback(
    async (discountValues: ProposalBudgetDiscount) => {
      await toast.promise(
        onSubmit({
          data: discountValues,
        }),
        { success: discount ? "Discount updated." : "Discount added." },
      );
      handleClose();
    },
    [onSubmit, handleClose, discount],
  );

  const handleRemove = useCallback(async () => {
    await toast.promise(onRemove(), {
      success: "Discount removed from the project budget.",
    });
    handleClose();
  }, [onRemove, handleClose]);

  const dialogNode = useMemo(() => {
    return (
      <BudgetDiscountDialog
        key={JSON.stringify(discount)} // start with reset default values each time discount changes
        discount={discount}
        maxDiscountValue={maxDiscountValue}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        open={isOpen}
      />
    );
  }, [
    handleClose,
    handleSubmit,
    handleRemove,
    isOpen,
    discount,
    maxDiscountValue,
  ]);

  return useMemo(() => {
    return {
      isOpen,
      openDialog,
      dialogNode,
    };
  }, [isOpen, openDialog, dialogNode]);
};
