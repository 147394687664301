import { createQueryKeys } from "@lukemorales/query-key-factory";

import { authenticationService } from "@ll-web/core/auth/async/AuthenticationService";
import { createMutationHook } from "@ll-web/utils/factories/createMutationHook";
import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const AuthQueries = createQueryKeys("auth", {
  activeUser: createQueryOption(
    authenticationService.getActiveUser.bind(authenticationService),
  ),
});

export const useQueryActiveUser = createQueryHook(AuthQueries.activeUser, {
  retry: 1,
  staleTime: 5 * 60 * 1000,
  // Prevent loops
  refetchOnMount: false,
  retryOnMount: false,
});

export const useGetJwtByPassword = createMutationHook(
  authenticationService.localAuthSignIn.bind(authenticationService),
);

export const useRegister = createMutationHook(
  authenticationService.register.bind(authenticationService),
);

export const useRequestResetPassword = createMutationHook(
  authenticationService.requestResetPassword.bind(authenticationService),
);

export const useUpdatePassword = createMutationHook(
  authenticationService.updatePassword.bind(authenticationService),
);
