import { createQueryKeys } from "@lukemorales/query-key-factory";

import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

import { proposalsService } from "./ProposalsService";

export const ProposalsQueries = createQueryKeys("Proposals", {
  findProposalProjects: createQueryOption(
    proposalsService.findProposalProjects.bind(proposalsService),
  ),
  findAll: createQueryOption(proposalsService.findAll.bind(proposalsService)),
  getById: createQueryOption(proposalsService.getById.bind(proposalsService)),
});

export const useFindProposalProjects = createQueryHook(
  ProposalsQueries.findProposalProjects,
);
export const useGetProposalById = createQueryHook(ProposalsQueries.getById);
export const useFindAllProposals = createQueryHook(ProposalsQueries.findAll);
