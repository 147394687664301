import { useCallback, useMemo } from "react";

import { useFormContext } from "react-hook-form";

import type { ProposalBudgetDiscount } from "@ll-web/features/budget/types";
import { basicInfoFields } from "@ll-web/features/internalProjects/projectForm/basicInfo/basicInfoSchema";
import { makeBasicInfoName } from "@ll-web/features/internalProjects/projectForm/basicInfo/makeBasicInfoName";
import type { ProjectFormValues } from "@ll-web/features/internalProjects/projectForm/projectFormSchema";

const proposalFieldName = makeBasicInfoName({
  field: basicInfoFields.proposal,
});

export const useManageProjectFormDiscount = () => {
  const { watch, setValue } = useFormContext<ProjectFormValues>();
  const proposal = watch(proposalFieldName);

  const handleSubmit = useCallback(
    async ({ data }: { data: ProposalBudgetDiscount }) => {
      setValue(proposalFieldName, { ...proposal, budgetDiscount: data });
    },
    [proposal, setValue],
  );

  const handleRemove = useCallback(async () => {
    setValue(proposalFieldName, { ...proposal, budgetDiscount: null });
  }, [proposal, setValue]);

  return useMemo(() => {
    return { onSubmit: handleSubmit, onRemove: handleRemove };
  }, [handleRemove, handleSubmit]);
};
