// replace AnyUser with context
import type { AccountType } from "@ll-web/core/auth/types";
// eslint-disable-next-line no-restricted-imports
import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
// eslint-disable-next-line no-restricted-imports
import { useUser } from "@ll-web/features/auth/hooks/useUser";

export type AnyUser = {
  id: string;
  email: string;
  accountType: AccountType;
  isActive: boolean;
  firstName?: string;
  lastName?: string;
};

export function useAnyUser(): AnyUser | undefined {
  const user = useUser();
  const creator = useCreator();

  if (user.isAuthorized) {
    return {
      id: user.activeUser._id,
      email: user.activeUser.email,
      accountType: user.activeUser.accountType,
      isActive: user.activeUser.isActive,
      firstName: user.activeUser.firstName,
      lastName: user.activeUser.lastName,
    };
  }

  if (creator.isAuthorized) {
    return {
      id: creator.activeCreator.id,
      email: creator.activeCreator.email,
      accountType: creator.activeCreator.accountType,
      isActive: creator.activeCreator.isActive,
      firstName: creator.activeCreator.firstName,
      lastName: creator.activeCreator.lastName,
    };
  }

  return undefined;
}
