import type { PropsWithChildren } from "react";

import { Link } from "react-router-dom";

import { LemonlightLogo } from "@ll-web/components/LemonlightLogo/LemonlightLogo";
import { ProgressSteps } from "@ll-web/components/ProgressSteps/ProgressSteps";
import { OnboardingSteps } from "@ll-web/core/auth/types";
import { AuthLayout } from "@ll-web/features/auth/components/AuthLayout/AuthLayout";
import { useOnboardNavigation } from "@ll-web/features/auth/pages/onboard/hooks/useOnboardNavigation";

export const OnboardingLayout = ({ children }: PropsWithChildren) => {
  const { currentStep } = useOnboardNavigation();

  return (
    <AuthLayout
      topBar={
        <Link to="/">
          <LemonlightLogo height="32" style={{ width: "max-content" }} />
        </Link>
      }
    >
      <ProgressSteps
        steps={Object.values(OnboardingSteps)}
        currentStep={currentStep}
        mb={3}
      />
      {children}
    </AuthLayout>
  );
};
