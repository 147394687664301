// @refresh reset

import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  type ComponentProps,
} from "react";

import { type TypographyProps } from "@mui/material";
import { isEqual, merge } from "lodash-es";
import { useDeepCompareEffect } from "react-use";
import { Transforms, type Descendant, type Editor } from "slate";
import { ReactEditor, Slate } from "slate-react";

import type { ProjectCommentMetadata } from "@ll-web/features/projectComments/types";

import { cleanupAfterCommentsPlugin } from "./comments/cleanupAfterCommentsPlugin";
import { CommentPopover } from "./comments/components/CommentPopover";
import type { TextEditorCommentsPluginProjectConfig } from "./comments/types";
import { EditableDecorated } from "./components/EditableDecorated";
import { RichtextSelectionToolbar } from "./components/RichtextSelectionToolbar";
import { SelectionToolbar } from "./components/SelectionToolbar";
import {
  selectionToolbarPlugins,
  textEditorDefaultConfig,
  textEditorDefaultInitialValue,
  toolbarPlugins,
} from "./consts";
import { ToolbarEditModeContextProvider } from "./contexts/ToolbarEditModeContext";
import { useKeydownHandler } from "./hooks/useKeyDownHandler";
import type { TextEditorConfig, TextEditorPluginsConfig } from "./types";
import { createConfiguredEditor } from "./utils/createConfiguredEditor";

export type StatelessTextEditorProps = Omit<
  ComponentProps<typeof Slate>,
  "editor" | "initialValue" | "children" | "onSubmit"
> & {
  config?: TextEditorConfig;
  onReadOnlySubmit?: (nodes: Descendant[]) => void;
  onEditorBlur?: (nodes: Descendant[]) => void;
  pluginsConfig?: TextEditorPluginsConfig;
  initialValue?: Descendant[];
  isReadOnly?: boolean;
  isWholeTextHighlighted?: boolean;
  textNodeProps?: TypographyProps;
  disablePartialCommenting?: boolean;
  placeholder?: string;
};

export const StatelessTextEditor = memo(
  forwardRef(
    (
      {
        config: _config,
        pluginsConfig,
        initialValue,
        isReadOnly,
        isWholeTextHighlighted,
        onReadOnlySubmit,
        textNodeProps,
        disablePartialCommenting,
        onEditorBlur,
        placeholder,
        ...props
      }: StatelessTextEditorProps,
      ref,
    ) => {
      const config = useMemo(() => _config ?? {}, [_config]);
      const editorInitialValue = useMemo(() => {
        return initialValue ?? textEditorDefaultInitialValue;
      }, [initialValue]);
      const [editorConfig, setEditorConfig] = useState(() =>
        merge({}, textEditorDefaultConfig, config),
      );

      const editor: Editor = useMemo(() => {
        if (editorConfig.plugins.comments && editorConfig.plugins.richText) {
          throw new Error(
            "Combined Comments and Richtext plugins are not supported yet.",
          );
        }

        return createConfiguredEditor(editorConfig);
      }, [editorConfig]);

      const onKeyDown = useKeydownHandler(editor);

      useDeepCompareEffect(() => {
        const newConfig = merge({}, textEditorDefaultConfig, config);

        // skip initially
        if (isEqual(editorConfig, newConfig)) {
          return;
        }

        cleanupAfterCommentsPlugin({
          editor,
          config: newConfig,
          previousConfig: editorConfig,
        });

        setEditorConfig(newConfig);
      }, [config]);

      useImperativeHandle(ref, () => {
        return {
          handleFocusEditor: setEditorFocus,
        };
      });

      const setEditorFocus = useCallback(
        (forceFocusEnd?: boolean) => {
          if (!editor.children.length) {
            return;
          }

          ReactEditor.focus(editor);
          if (!editor.selection?.focus.offset || forceFocusEnd) {
            Transforms.move(editor, { distance: 9999999999 });
          }
        },
        [editor],
      );

      const handleEditorBlur = async () => {
        editor.deselect();
        onEditorBlur?.(editor.children);
      };

      return (
        <ToolbarEditModeContextProvider>
          <Slate
            editor={editor}
            key={JSON.stringify(editorConfig)}
            initialValue={editorInitialValue}
            {...props}
          >
            {toolbarPlugins.some(
              (pluginName) => editorConfig?.plugins?.[pluginName],
            ) && <RichtextSelectionToolbar config={editorConfig} />}
            <EditableDecorated
              textNodeProps={textNodeProps}
              config={editorConfig}
              onKeyDown={onKeyDown}
              onBlur={onEditorBlur ? handleEditorBlur : undefined}
              readOnly={isReadOnly}
              isWholeTextHighlighted={isWholeTextHighlighted}
              placeholder={placeholder}
            />
            {selectionToolbarPlugins.some(
              (pluginName) => editorConfig?.plugins?.[pluginName],
            ) && (
              <>
                <SelectionToolbar
                  config={editorConfig}
                  metadata={
                    (
                      pluginsConfig?.comments as
                        | TextEditorCommentsPluginProjectConfig
                        | undefined
                    )?.metadata as ProjectCommentMetadata
                  }
                  isEnabled={
                    !isReadOnly || !pluginsConfig?.comments?.isReadOnly
                  }
                  disablePartialCommenting={disablePartialCommenting}
                />
                {editorConfig.plugins.comments && (
                  <CommentPopover
                    commentsConfig={pluginsConfig?.comments}
                    onReadOnlySubmitEditor={onReadOnlySubmit}
                  />
                )}
              </>
            )}
          </Slate>
        </ToolbarEditModeContextProvider>
      );
    },
  ),
);

StatelessTextEditor.displayName = "StatelessTextEditor";
