import { identify, Identify } from "@amplitude/analytics-browser";
import type { ValidPropertyType } from "@amplitude/analytics-types";

type AmplitudeUserPropertyPayload = {
  property: string;
  value: ValidPropertyType;
};

export const updateAmplitudeUserProperty = (
  payload: AmplitudeUserPropertyPayload | AmplitudeUserPropertyPayload[],
) => {
  const identifyEvent = new Identify();
  Array.isArray(payload)
    ? payload.forEach(({ property, value }) =>
        identifyEvent.set(property, value),
      )
    : identifyEvent.set(payload.property, payload.value);
  identify(identifyEvent);
};
