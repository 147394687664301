import type { AccountType, User } from "@ll-web/core/auth/types";
import type {
  BrandRoleEnum,
  BrandUserWithProfile,
} from "@ll-web/features/brands/types";

export function filterUsersByType({
  users,
  type,
}: {
  users: User[] | null | undefined;
  type: AccountType;
}): User[] | null {
  return users?.filter((user) => user.accountType === type) ?? null;
}

export function filterBrandUsersByType({
  users,
  type,
  role,
}: {
  users: BrandUserWithProfile[] | null | undefined;
  type: AccountType;
  role?: BrandRoleEnum;
}): BrandUserWithProfile[] | null {
  return (
    users?.filter((brandUser) => {
      if (role && brandUser.role !== role) {
        return false;
      }

      return brandUser.user?.accountType === type;
    }) ?? null
  );
}
