import { useCallback, useMemo } from "react";

import { LoadingButton } from "@mui/lab";
import { Tooltip, type ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useActivityTracker } from "@ll-web/core/analytics/useActivityTracker";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import {
  useWizardNavigationContext,
  WizardFlowType,
} from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { useFinalizeWizard } from "@ll-web/features/projectWizard/hooks/useFinalizeWizard";
import {
  useHomepagePagePath,
  useProjectHomepagePath,
} from "@ll-web/features/router/hooks";

type FinalizeWizardButtonProps = ButtonProps & {
  onBlockedFinalizeClick?: () => void;
};

export const FinalizeWizardButton = ({
  children,
  onBlockedFinalizeClick,
  ...props
}: FinalizeWizardButtonProps) => {
  const { flowType, reasonForBlockedFinalize, isLastStep } =
    useWizardNavigationContext();
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const isInternalUser = isUserInternal(activeUser);
  const flags = useAppFlags();
  const homepagePath = useHomepagePagePath();
  const projectHomepagePath = useProjectHomepagePath();
  const { trackEvent } = useActivityTracker();

  const targetPath = flags[FeatureFlagName.ProjectSpace]
    ? projectHomepagePath(activeProject.id)
    : homepagePath;
  const navigate = useNavigate();
  const navigateAfterFinalize = useCallback(() => {
    navigate(targetPath);
  }, [targetPath, navigate]);

  const {
    onClickFinalize,
    finalizeRequestState,
    sentimentDialog,
    notifyForReviewDialog,
  } = useFinalizeWizard({
    onFinalize: navigateAfterFinalize,
  });

  const label = useMemo(() => {
    if (children) {
      return children;
    }

    if (
      flowType === WizardFlowType.CreativeBrief ||
      flowType === WizardFlowType.EditCreativeDeck ||
      flowType === WizardFlowType.EditCallSheet
    ) {
      return "Submit";
    }

    if (isInternalUser) {
      return "Share for Review";
    }

    return "Submit for Review";
  }, [children, flowType, isInternalUser]);

  const handleFinalize = useCallback(async () => {
    if (!reasonForBlockedFinalize) {
      trackEvent(ActivityType.CreativeBriefInputClickedShareForReview, {
        projectId: activeProject.id,
        projectName: activeProject.title,
        senderRole: activeUser.accountType,
        videoStyle: activeProject.style,
      });

      return await onClickFinalize();
    }

    onBlockedFinalizeClick?.();
    toast.error(reasonForBlockedFinalize);
  }, [
    onClickFinalize,
    onBlockedFinalizeClick,
    reasonForBlockedFinalize,
    activeProject,
    activeUser.accountType,
    trackEvent,
  ]);

  return (
    <>
      <Tooltip title={reasonForBlockedFinalize} placement="left" arrow={true}>
        <div>
          <LoadingButton
            variant="contained"
            disabled={!isLastStep}
            loading={finalizeRequestState?.status === "pending"}
            {...props}
            onClick={handleFinalize}
            sx={{ width: "max-content", ...props.sx }}
          >
            {label}
          </LoadingButton>
        </div>
      </Tooltip>
      {notifyForReviewDialog.notifyForReviewDialogNode}
      {sentimentDialog.node}
    </>
  );
};
