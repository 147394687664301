import { useContext } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { ActiveProjectContext } from "@ll-web/features/projectWizard/contexts/ActiveProjectContext";
import { useFindProposalProjects } from "@ll-web/features/proposals/async/useProposalsQueries";
import { assertDefined } from "@ll-web/utils/types/types";

export const useActiveProject = (): {
  activeProject: ProjectWithDeliverables;
} => {
  const activeProject = useContext(ActiveProjectContext);
  assertDefined(activeProject, "Missing active project context");

  return { activeProject };
};

export const useActiveProjectId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const projectId = pathParams.id ?? queryParams.get("projectId");
  assertDefined(projectId, "Missing active project id in path or query");

  return projectId;
};

export const useActiveProjectIdFromProposalOrParams = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();
  const proposalId = pathParams.proposalId;

  const projectId = pathParams.id ?? queryParams.get("projectId");

  const { data: proposalProjects } = useFindProposalProjects(
    { proposalId: proposalId! },
    {
      enabled: !!proposalId,
    },
  );

  if (projectId) {
    return projectId;
  }

  // TODO: Handle multiple projects in proposal
  if (proposalProjects?.[0]?.id) {
    return proposalProjects[0].id;
  }

  throw new Error(
    "Can't find active project ID either in the params and in Creative Proposal",
  );
};
