import { decodeToken } from "react-jwt";

import {
  automapDates,
  type SerializedJson,
} from "@ll-web/core/api/helpers/dateMapper";
import { heroHttpClient } from "@ll-web/core/api/HeroHttpClient";
import type {
  ActiveUserProfile,
  AuthCallbackResponseDto,
  GoogleOAuthCallbackParams,
  LocalAuthPayloadDto,
  RegisterDto,
  RequestResetPasswordDto,
  UpdatePasswordDto,
  UserJwtDto,
  UserTokens,
} from "@ll-web/core/auth/types";
import { assertDefined } from "@ll-web/utils/types/types";

const USER_TOKENS_LS_KEY = "userTokens";

class AuthenticationService {
  async localAuthSignIn(args: LocalAuthPayloadDto): Promise<UserTokens> {
    const tokens = await heroHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: "POST",
        url: "/v1/auth/login",
        data: args,
      },
      withAuth: false,
    });

    return tokens;
  }

  async getActiveUser(): Promise<ActiveUserProfile> {
    const user = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<ActiveUserProfile>
    >({
      config: {
        method: "GET",
        url: "/v1/auth/profile",
      },
      handleUnauthorized: () => {
        this.clearUserTokens();
        throw new Error("Unauthorized. Please log in again");
      },
    });

    return automapDates<ActiveUserProfile>(user);
  }

  async register(args: RegisterDto): Promise<UserTokens> {
    const tokens = await heroHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: "POST",
        url: "/v1/auth/register",
        data: args,
      },
      withAuth: false,
    });

    return tokens;
  }

  async requestResetPassword(args: RequestResetPasswordDto): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/auth/request-reset-password",
        data: args,
      },
      withAuth: false,
    });
  }

  async updatePassword(args: UpdatePasswordDto): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/auth/update-password",
        data: args,
      },
      withAuth: false,
    });
  }

  async continueWithGoogle(
    args: GoogleOAuthCallbackParams,
  ): Promise<AuthCallbackResponseDto> {
    const response =
      await heroHttpClient.unwrappedHttpRequest<AuthCallbackResponseDto>({
        config: {
          method: "GET",
          url: "/v1/auth/google/callback",
          params: args,
        },
        withAuth: false,
      });

    return response;
  }

  // Used to refresh the tokens after updating account information
  async reloadTokens() {
    const existingTokens = this.getUserTokens();
    assertDefined(existingTokens, "existingTokens");
    const tokens = await this.makeReloadTokens(existingTokens);
    await this.saveUserTokens(tokens);
  }

  private async makeReloadTokens(currentTokens: UserTokens) {
    return await heroHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: "POST",
        url: "/v1/auth/reload-token",
        data: currentTokens,
      },
    });
  }

  async saveUserTokens(tokens: UserTokens) {
    localStorage.setItem(USER_TOKENS_LS_KEY, JSON.stringify(tokens));
  }

  getUserTokens() {
    const tokens = localStorage.getItem(USER_TOKENS_LS_KEY);

    return tokens ? (JSON.parse(tokens) as UserTokens) : null;
  }

  clearUserTokens() {
    localStorage.removeItem(USER_TOKENS_LS_KEY);
  }

  // NOTE: Don't use this method for authorization
  // Use only if you can't wait to fetch user profile
  getUserJwt(): UserJwtDto | null {
    const tokens = this.getUserTokens();
    if (!tokens?.accessToken) {
      return null;
    }

    return decodeToken(tokens.accessToken);
  }
}

export const authenticationService = new AuthenticationService();
