import { authenticationService } from "@ll-web/core/auth/async/AuthenticationService";
import { AccountType, type ActiveUserProfile } from "@ll-web/core/auth/types";
import { useQueryActiveUser } from "@ll-web/features/auth/async/hooks";

type UseUserReturn =
  | {
      isLoading: boolean;
      isAuthorized: true;
      activeUser: ActiveUserProfile;
    }
  | {
      isLoading: boolean;
      isAuthorized: false;
      activeUser: undefined;
    };

export function useUser(): UseUserReturn {
  const token = authenticationService.getUserJwt();

  const activeUserQuery = useQueryActiveUser(
    {},
    {
      enabled: !!token && token.accountType !== AccountType.Creator,
      meta: {
        supressErrorToast: true,
      },
    },
  );
  const activeUser = activeUserQuery.data;

  if (activeUser) {
    return {
      isLoading: false,
      isAuthorized: true,
      activeUser,
    };
  } else {
    return {
      isLoading: activeUserQuery.isLoading,
      isAuthorized: false,
      activeUser: undefined,
    };
  }
}
