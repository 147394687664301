import { Outlet, type RouteObject } from "react-router-dom";

import { lazyLoad, withLoader } from "@ll-web/core/router/helpers";
import { DashboardLayout } from "@ll-web/creator/dashboard/components/layout/DashboardLayout";

import { IsLoggedInGuard } from "./auth/guards/IsLoggedInGuard";

const LoginPage = withLoader(
  lazyLoad(() => import("./auth/pages/login/LoginPage")).LoginPage,
);
const ResetPasswordController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/creator/auth/pages/resetPassword/ResetPasswordController"
      ),
  ).ResetPasswordController,
);
const RegisterPage = withLoader(
  lazyLoad(() => import("./auth/pages/register/RegisterPage")).RegisterPage,
);
const AuthRedirectController = withLoader(
  lazyLoad(() => import("./auth/pages/AuthRedirectController"))
    .AuthRedirectController,
);

const CreatorDashboardPage = withLoader(
  lazyLoad(() => import("./dashboard/pages/CreatorDashboardPage"))
    .CreatorDashboardPage,
);

export const creatorRoutes: RouteObject[] = [
  {
    path: "creator",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <AuthRedirectController />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordController />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "dashboard",
        element: (
          <IsLoggedInGuard>
            <Outlet />
          </IsLoggedInGuard>
        ),
        children: [
          {
            path: "",
            element: (
              <DashboardLayout>
                <CreatorDashboardPage />
              </DashboardLayout>
            ),
          },
        ],
      },
      // {
      //   path: "settings",
      //   element: (
      //     <IsLoggedInGuard>
      //       <DashboardLayout>
      //         <Outlet />
      //       </DashboardLayout>
      //     </IsLoggedInGuard>
      //   ),
      //   children: [
      //     {
      //       path: "my-account",
      //       element: <MyAccountPage />,
      //     },
      //   ],
      // },
    ],
  },
];
