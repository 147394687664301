import {
  heroHttpClient,
  type HttpRequestArgs,
} from "@ll-web/core/api/HeroHttpClient";
import { ignoreUnauthorizedOnSharedEndpoint } from "@ll-web/features/auth/utils/auth";
import type {
  GetProjectTimelineParams,
  ProjectTimeline,
  TimelineAdjustment,
  UpdateProjectTimelineItemDate,
} from "@ll-web/features/projectTimeline/types";

class ProjectTimelineService {
  async getTimeline(
    args: GetProjectTimelineParams & { httpArgs?: Partial<HttpRequestArgs> },
  ): Promise<ProjectTimeline> {
    return await heroHttpClient.unwrappedHttpRequest<ProjectTimeline>({
      config: {
        method: "GET",
        url: `/v1/project-timeline/${args.projectId}`,
        params: {
          baseDate: args.baseDate,
        },
      },
      handleUnauthorized: ignoreUnauthorizedOnSharedEndpoint,
    });
  }

  async createTimelineAdjustment({
    projectId,
    ...args
  }: UpdateProjectTimelineItemDate): Promise<TimelineAdjustment> {
    return await heroHttpClient.unwrappedHttpRequest<TimelineAdjustment>({
      config: {
        method: "PUT",
        url: `/v1/project-timeline/${projectId}/adjustments`,
        data: args,
      },
    });
  }
}

export const projectTimelineService = new ProjectTimelineService();
