import { heroHttpClient } from "@ll-web/core/api/HeroHttpClient";
import { ignoreUnauthorizedOnSharedEndpoint } from "@ll-web/features/auth/utils/auth";
import type {
  AddBudgetItemArgs,
  BudgetLineItemDto,
  GetBudgetArgs,
  ProjectBudget,
  RemoveBudgetItemArgs,
  UpdateBudgetItemArgs,
} from "@ll-web/features/budget/types";

class BudgetService {
  async getBudgetByProject(args: {
    projectId: string;
  }): Promise<ProjectBudget> {
    const budget = await heroHttpClient.unwrappedHttpRequest<ProjectBudget>({
      config: {
        method: "GET",
        url: `/v1/budget/project/${args.projectId}`,
      },
      handleUnauthorized: ignoreUnauthorizedOnSharedEndpoint,
    });

    return budget;
  }

  async getBudget(args: GetBudgetArgs): Promise<ProjectBudget> {
    const budget = await heroHttpClient.unwrappedHttpRequest<ProjectBudget>({
      config: {
        method: "POST",
        url: "/v1/budget",
        data: args,
      },
    });

    return budget;
  }

  async addCustomBudgetItem({
    projectId,
    ...args
  }: AddBudgetItemArgs): Promise<BudgetLineItemDto> {
    const budgetItem =
      await heroHttpClient.unwrappedHttpRequest<BudgetLineItemDto>({
        config: {
          method: "POST",
          url: `/v1/budget/${projectId}/line-item`,
          data: args,
        },
      });

    return budgetItem;
  }

  async updateCustomBudgetItem({
    id,
    projectId,
    ...args
  }: UpdateBudgetItemArgs): Promise<BudgetLineItemDto> {
    const budgetItem =
      await heroHttpClient.unwrappedHttpRequest<BudgetLineItemDto>({
        config: {
          method: "PUT",
          url: `/v1/budget/${projectId}/line-item/${id}`,
          data: args,
        },
      });

    return budgetItem;
  }

  async removeCustomBudgetItem({
    id,
    projectId,
  }: RemoveBudgetItemArgs): Promise<ProjectBudget> {
    const budget = await heroHttpClient.unwrappedHttpRequest<ProjectBudget>({
      config: {
        method: "DELETE",
        url: `/v1/budget/${projectId}/line-item/${id}`,
      },
    });

    return budget;
  }
}

export const budgetService = new BudgetService();
