import type { ReactNode } from "react";

import { ErrorBoundary } from "@sentry/react";

import { ErrorPage } from "@ll-web/components/statusPages/ErrorPage";

type GlobalErrorBoundaryProps = {
  children: ReactNode;
};

export const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  return <ErrorBoundary fallback={<ErrorPage />}>{children}</ErrorBoundary>;
};
