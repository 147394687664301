import { useQueryClient } from "@tanstack/react-query";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { authenticationService } from "@ll-web/core/auth/async/AuthenticationService";
import type { User } from "@ll-web/core/auth/types";
import { Pages } from "@ll-web/core/router/pages";

export function useLogOut({ activeUser }: { activeUser?: User } = {}) {
  const queryClient = useQueryClient();

  return async ({
    redirect = Pages.Login,
  }: { redirect?: string | false } = {}) => {
    if (activeUser) {
      activityTracker.log(ActivityType.LogoutUser);
    }

    sessionStorage.clear();
    authenticationService.clearUserTokens();

    if (redirect) {
      // Load the page from scratch
      window.location.href = redirect;
    } else {
      await queryClient.resetQueries();
    }
  };
}
