import type { ReactNode } from "react";

import {
  Divider,
  Stack,
  Typography,
  type StackProps,
  type TypographyProps,
} from "@mui/material";

import { useGetDebouncedBudget } from "@ll-web/features/budget/async/useBudgetQueries";
import { usePriceAdjustments } from "@ll-web/features/budget/hooks/usePriceAdjustments";
import type { ProjectFormInput } from "@ll-web/features/internalProjects/types";
import { formatNumberToDollars } from "@ll-web/utils/helpers/helpers";

import { BudgetSummaryDiscountItem } from "./BudgetSummaryDiscountItem";

type BudgetSummaryProps = {
  project: ProjectFormInput;
  isInProjectForm?: boolean;
};

export const BudgetSummary = ({
  project,
  isInProjectForm,
}: BudgetSummaryProps) => {
  const discount = project.proposal?.budgetDiscount ?? undefined;
  const { data } = useGetDebouncedBudget({
    project,
    discount,
  });
  const { discountData, rushOrderData } = usePriceAdjustments({ data });

  return (
    <Stack
      sx={{
        py: 1.5,
        background: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
      }}
    >
      <BudgetSummarySection py={1}>
        <Typography variant="subtitle1">Project Budget</Typography>
      </BudgetSummarySection>
      <BudgetSummarySection>
        <BudgetSummaryItem
          title="Pre-Production"
          value={data?.preProduction.subtotal}
        />
        <BudgetSummaryItem
          title="Production"
          value={data?.production.subtotal}
        />
        <BudgetSummaryItem
          title="Post-Production"
          value={data?.postProduction.subtotal}
        />
      </BudgetSummarySection>

      <BudgetSummarySection py={1}>
        <BudgetSummaryItem title="Subtotal" value={data?.subtotal} />
        {!!rushOrderData && (
          <BudgetSummaryItem
            title="Rush Order"
            value={rushOrderData?.valueInCents}
          />
        )}
        <BudgetSummaryDiscountItem
          discount={discount}
          discountData={discountData}
          maxDiscountValue={data?.subtotal}
          projectId={
            project.id && project.proposal?.proposalId ? project.id : undefined
          }
          isInProjectForm={isInProjectForm}
        />
      </BudgetSummarySection>

      <BudgetSummarySection isLast>
        <BudgetSummaryItem
          title="Total"
          value={data?.total}
          typographyProps={{ variant: "h6" }}
        />
      </BudgetSummarySection>
    </Stack>
  );
};

const BudgetSummarySection = ({
  isLast,
  children,
  ...props
}: StackProps & { isLast?: boolean }) => {
  return (
    <>
      <Stack gap={1} px={2} py={2} {...props}>
        {children}
      </Stack>
      {!isLast && <Divider sx={{ mx: 2 }} />}
    </>
  );
};

type BudgetSummaryItemProps = {
  title: ReactNode;
  typographyProps?: TypographyProps;
  value?: number;
};

const BudgetSummaryItem = ({
  title,
  typographyProps,
  value,
}: BudgetSummaryItemProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1" {...typographyProps}>
        {title}
      </Typography>
      {value !== undefined && (
        <Typography {...typographyProps}>
          {formatNumberToDollars(value / 100)}
        </Typography>
      )}
    </Stack>
  );
};
