import { type ReactNode } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { SmallWhiteTextField } from "@ll-web/components/form/SmallWhiteTextField/SmallWhiteTextField";
import { TextEditorInline } from "@ll-web/components/form/TextEditorInlineWrapper/TextEditorInlineWrapper";
import type { User } from "@ll-web/core/auth/types";
import type { ReviewEmailData } from "@ll-web/features/reviews/types";
import { TextEditorPluginsEnum } from "@ll-web/features/textEditor/types";
import { assertDefined } from "@ll-web/utils/types/types";

import { MultiUserAutocomplete } from "./MultiUserAutocomplete";
import { notifyForReviewSchema } from "./notify-for-review.schema";

type NotifyModalFormProps = {
  defaultReceiversData: User[];
  brandId: string;
  reviewEmailData: ReviewEmailData;
  headingContent: ReactNode;
  submitButtonContent: string;
  onSubmitForReview: (payload: ReviewEmailData) => Promise<void>;
  onClose: () => void;
};

/**
 * TODO: For future reference - possible refactor: could use a CustomDialog
 * Also this is dialog content rendered in dialog parent component - we should avoid this practice
 */
export const NotifyModalForm = ({
  defaultReceiversData,
  brandId,
  reviewEmailData,
  headingContent,
  submitButtonContent,
  onSubmitForReview,
  onClose,
}: NotifyModalFormProps) => {
  assertDefined(brandId, "brandId"); // sanity check
  const { mutateAsync: doSubmit, isPending } = useMutation({
    mutationFn: onSubmitForReview,
    meta: {
      supressErrorToast: true,
    },
  });

  const methods = useForm<ReviewEmailData>({
    defaultValues: reviewEmailData,
    resolver: yupResolver(notifyForReviewSchema),
    mode: "onBlur",
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const handleSubmit = (data: ReviewEmailData) => {
    doSubmit(data);
  };

  return (
    <Stack component="form" onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Stack
          sx={{
            flexGrow: 1,
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography>{headingContent}</Typography>
          <MultiUserAutocomplete
            label="To"
            defaultReceiversData={defaultReceiversData}
            brandId={brandId}
          />
          <Controller
            control={methods.control}
            name="title"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                label="Title"
              />
            )}
          />
          <Controller
            control={methods.control}
            name="body"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <TextEditorInline
                label="Message"
                error={fieldState.error?.message}
                type="html"
                config={{
                  plugins: {
                    [TextEditorPluginsEnum.RichText]: true,
                    [TextEditorPluginsEnum.Comments]: false,
                    [TextEditorPluginsEnum.History]: true,
                  },
                }}
                value={reviewEmailData.body}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
      </FormProvider>
      <DialogActions sx={{ py: 3, px: 0 }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending}
        >
          {submitButtonContent}
        </Button>
      </DialogActions>
    </Stack>
  );
};
