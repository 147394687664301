import { type PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import Status404 from "@ll-web/components/statusPages/Status404";
import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { AnalyticsProvider } from "@ll-web/core/analytics/AnalyticsContext";
import { mapProjectToAnalytics } from "@ll-web/features/analytics/eventUtils";
import { useGetProjectBaseDataById } from "@ll-web/features/projects/async/useProjectsQueries";

export const ActiveProjectBaseDataGuard = ({ children }: PropsWithChildren) => {
  const { id } = useParams();
  const { data: activeProject, isPending } = useGetProjectBaseDataById({ id });

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProject) {
    return <Status404 />;
  }

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics(activeProject)}>
      {children}
    </AnalyticsProvider>
  );
};
