import { useRef, useState } from "react";

import {
  DeleteOutlined,
  EditNoteOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import {
  Fade,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  type IconButtonProps,
} from "@mui/material";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import type { ProjectCommentMetadata } from "@ll-web/features/projectComments/types";
import type { TextEditorCommentsPluginConfig } from "@ll-web/features/textEditor/comments/types";
import { useRemoveConfirmationDialog } from "@ll-web/utils/hooks/useRemoveConfirmationDialog";

type CommentMoreActionsMenuProps = IconButtonProps & {
  onEdit: VoidFunction;
  onRemove: () => Promise<void>;
  onRemoveCancel: VoidFunction;
  isReadOnly?: boolean;
  commentsConfig?: TextEditorCommentsPluginConfig;
  isEditable?: boolean;
};

export const CommentMoreActionsMenu = ({
  onEdit,
  onRemove,
  isReadOnly,
  commentsConfig,
  onRemoveCancel,
  isEditable = true,
  ...props
}: CommentMoreActionsMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleEdit = () => {
    closeMenu();
    onEdit();
  };

  const { openRemoveConfirmationModal, removeConfirmationDialogNode } =
    useRemoveConfirmationDialog({
      dialogProps: {
        confirmationTitle: "Delete the comment",
        confirmationContent: (
          <Stack gap={1.25}>
            <Typography variant="body1" color="text.secondary">
              Would you like to delete this comment?
            </Typography>
          </Stack>
        ),
        removeButtonContent: "Delete",
      },
      onRemove,
      onCancel: onRemoveCancel,
    });

  const handleClickMenuItemDelete = () => {
    if (commentsConfig && "metadata" in commentsConfig) {
      activityTracker.log({
        type: ActivityType.WizardOutputClickedRemoveComment,
        metadata: commentsConfig.metadata as ProjectCommentMetadata,
      });
    }
    openRemoveConfirmationModal();
  };

  return (
    <>
      <Tooltip title="More actions" arrow placement="top">
        <IconButton
          {...props}
          onClick={openMenu}
          ref={menuAnchorRef}
          sx={{
            ...props.sx,
            ...(isMenuOpen && {
              backgroundColor: "action.hover",
            }),
          }}
        >
          <MoreHoriz />
        </IconButton>
      </Tooltip>

      <Menu
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            px: 0,
            py: 1,
          },
        }}
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={closeMenu}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: 8,
          horizontal: "left",
        }}
        disablePortal
      >
        {isEditable && (
          <MenuItem onClick={handleEdit} disabled={isReadOnly}>
            <ListItemIcon color="text.secondary">
              <EditNoteOutlined />
            </ListItemIcon>
            <Typography>Edit</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={handleClickMenuItemDelete} disabled={isReadOnly}>
          <ListItemIcon color="text.secondary">
            <DeleteOutlined />
          </ListItemIcon>
          <Typography>Delete</Typography>
        </MenuItem>
      </Menu>

      {removeConfirmationDialogNode}
    </>
  );
};
