import { useMemo } from "react";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { merge } from "lodash-es";

import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { userSettingsService } from "@ll-web/features/auth/userSettings/async/UserSettingsService";
import { defaultUserSettings } from "@ll-web/features/auth/userSettings/config/defaultUserSettings";
import type { UserSettings } from "@ll-web/features/auth/userSettings/types";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const UserSettingsQueries = createQueryKeys("userSettings", {
  getActiveUserSettings: createQueryOption(
    userSettingsService.getActiveUserSettingsByUserId.bind(userSettingsService),
  ),
});

export function useGetActiveUserSettings() {
  const { activeUser } = useUser();

  const query = useQuery({
    ...UserSettingsQueries.getActiveUserSettings({
      userId: activeUser?._id ?? "",
    }),
    enabled: !!activeUser,
    refetchOnMount: false,
    retry: 1,
    initialDataUpdatedAt: Date.now() - 60_000, // already stale - refetch on use
  });

  const data: UserSettings = useMemo(() => {
    return merge(defaultUserSettings, query.data) as Required<UserSettings>;
  }, [query.data]);

  return {
    ...query,
    data,
  };
}
