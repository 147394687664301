import { useCallback, useMemo, useState, type ReactNode } from "react";

import type { ButtonProps, DialogProps } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

// disabled, cause this hook is deprecated
// eslint-disable-next-line no-restricted-imports
import { ConfirmDialog } from "@ll-web/components/ConfirmDialog/ConfirmDialog";

type UseRemoveConfirmationDialogArgs<T = void> = {
  onRemove: (arg?: T) => Promise<void>;
  onCancel?: VoidFunction;
  dialogProps: Partial<DialogProps> & {
    confirmationTitle: ReactNode;
    confirmationContent: ReactNode;
    removeButtonContent?: string;
    confirmButtonColor?: ButtonProps["color"];
  };
};

/**
 * @deprecated
 * @description For future reference, this hook is kept for backwards compatibility. If possible, use the ConfirmDialog component directly.
 */
export const useRemoveConfirmationDialog = ({
  onRemove,
  onCancel,
  dialogProps,
}: UseRemoveConfirmationDialogArgs) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { mutateAsync: doRemove, isPending } = useMutation({
    mutationFn: onRemove,
    meta: {
      supressErrorToast: true,
    },
  });

  const openRemoveConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const handleConfirmRemove = useCallback(async () => {
    await doRemove();

    setIsConfirmationModalOpen(false);
  }, [doRemove]);

  const handleCancelRemove = useCallback(() => {
    setIsConfirmationModalOpen(false);
    onCancel?.();
  }, [onCancel]);

  const removeConfirmationDialogNode = useMemo(
    () => (
      <ConfirmDialog
        open={isConfirmationModalOpen}
        title={dialogProps.confirmationTitle}
        body={dialogProps.confirmationContent}
        confirmLabel={dialogProps.removeButtonContent ?? "Remove"}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        isLoading={isPending}
        confirmButtonProps={{
          color: dialogProps.confirmButtonColor ?? "error",
        }}
      />
    ),
    [
      isConfirmationModalOpen,
      dialogProps.confirmationTitle,
      dialogProps.confirmationContent,
      dialogProps.removeButtonContent,
      dialogProps.confirmButtonColor,
      handleConfirmRemove,
      handleCancelRemove,
      isPending,
    ],
  );

  return useMemo(
    () => ({
      isConfirmationModalOpen,
      openRemoveConfirmationModal,
      closeRemoveConfirmationModal: handleCancelRemove,
      removeConfirmationDialogNode,
    }),
    [
      isConfirmationModalOpen,
      handleCancelRemove,
      openRemoveConfirmationModal,
      removeConfirmationDialogNode,
    ],
  );
};
