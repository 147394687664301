import type { User } from "@ll-web/core/auth/types";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";

type GetProjectStatusArgs = {
  project: ProjectWithDeliverables;
  activeUser: User;
};

export const getProjectStatus = ({
  project,
  activeUser,
}: GetProjectStatusArgs) => {
  return (
    (!isUserInternal(activeUser) && project.externalStatus) || project.status!
  );
};
