import { useCallback, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSlateStatic } from "slate-react";
import * as yup from "yup";

import { useToolbarEditModeContext } from "@ll-web/features/textEditor/contexts/ToolbarEditModeContext";
import { getCurrentLinkNode } from "@ll-web/features/textEditor/richtext/linkQueries";
import {
  insertLink,
  unwrapLink,
  updateLink,
} from "@ll-web/features/textEditor/richtext/linkTransforms";
import { isUrl } from "@ll-web/utils/helpers/testUrl";

type LinkDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * TODO: For future reference - possible refactor: could use a CustomDialog with custom actions
 */
export const LinkDialog = ({ isOpen, onClose }: LinkDialogProps) => {
  const editor = useSlateStatic();
  const { editMode } = useToolbarEditModeContext();

  const methods = useForm({
    defaultValues: {
      url: "",
    },
    resolver: yupResolver<{ url: string }>(
      yup.object().shape({
        url: yup.string().required().test({
          name: "isUrl",
          message: "Value is not valid URL. Prefix is required.",
          test: isUrl,
        }),
      }),
    ),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (isOpen && ["editLink"].includes(editMode)) {
      const currentLink = getCurrentLinkNode(editor);
      if (currentLink) {
        methods.reset({
          url: currentLink.url,
        });
      }
    } else {
      methods.reset({ url: "" });
    }
  }, [editor, editMode, isOpen, methods]);

  const handleSubmit = useCallback(
    ({ url }: { url: string }) => {
      if (!url) {
        throw new Error("You need to provide valid URL to submit");
      }

      if (editMode === "editLink") {
        updateLink(editor, url);
      } else {
        insertLink(editor, url);
      }
      methods.reset();
      onClose();
    },
    [onClose, editMode, methods, editor],
  );

  const handleDelete = () => {
    unwrapLink(editor);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      onMouseUp={(e) => e.stopPropagation()}
    >
      <FormProvider {...methods}>
        <DialogTitle>
          {editMode === "editLink" ? "Edit Link" : "Add Link"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <Controller
              name="url"
              control={methods.control}
              render={({ field: { ref: _, ...field }, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    label="URL"
                    fullWidth
                    autoFocus
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          {editMode === "editLink" && (
            <Button color="error" variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button
            type="submit"
            onClick={methods.handleSubmit(handleSubmit)}
            variant="contained"
          >
            {editMode === "editLink" ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
