import * as yup from "yup";

import { validateMsa } from "@ll-web/features/contracts/components/ContractForm/utils";
import {
  contextIsCreativeProposalFlow,
  contextProjectDeliverables,
  contextProjectStyle,
} from "@ll-web/features/internalProjects/projectForm/validationContext";
import {
  FootageTypeEnum,
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
  ProjectTimelineEnum,
  RawFootageTypeEnum,
} from "@ll-web/features/projects/enums";
import { yupTestNotDefined } from "@ll-web/utils/helpers/yup";

export const basicInfoFields = {
  msa: "msa",
  proposal: "proposal",
  style: "style",
  title: "title",
  deliverables: "deliverables",
  rawFootageType: "rawFootageType",
  footageType: "footageType",
  timeline: "timeline",
  dontUseForMarketing: "dontUseForMarketing",
} as const;

export type PrefixedBasicInfoFields =
  `basicInfo.${(keyof typeof basicInfoFields)[][number]}`;

export const basicInfoSchema = yup.object().shape({
  [basicInfoFields.msa]: yup
    .string()
    .when(
      contextIsCreativeProposalFlow,
      ([isCreativeProposalFlow], msaSchema) => {
        if (isCreativeProposalFlow) {
          return msaSchema.notRequired().default(undefined);
        }

        return msaSchema
          .required()
          .test(
            "validate-msa",
            "MSA must only contain numbers and letters",
            validateMsa,
          )
          .default("");
      },
    ),
  [basicInfoFields.proposal]: yup
    .object()
    .optional()
    .shape({
      proposalId: yup.string().optional(),
      overview: yup.string().optional(),
      budgetDiscount: yup
        .object()
        .optional()
        .shape({
          fixed: yup.number().nullable(),
          percentage: yup.number().nullable(),
        })
        .nullable(),
      sampleVideos: yup.array().optional(),
      processNotes: yup
        .object()
        .optional()
        .shape({
          preproduction: yup.object().optional().shape({
            projectAlignment: yup.string().optional(),
            preproductionCollaboration: yup.string().optional(),
            productionPreparation: yup.string().optional(),
          }),
          postproduction: yup.object().optional().shape({
            productionDays: yup.string().optional(),
            postproduction: yup.string().optional(),
          }),
        }),
    })
    .when(
      contextIsCreativeProposalFlow,
      ([isCreativeProposalFlow], proposalSchema) => {
        if (isCreativeProposalFlow) {
          return proposalSchema.required().default({});
        }

        return proposalSchema.notRequired().default(undefined);
      },
    ),
  [basicInfoFields.style]: yup
    .string()
    .oneOf(Object.values(ProjectStyleEnum))
    .required()
    .default(null),
  [basicInfoFields.title]: yup.string().required().default(""),
  [basicInfoFields.deliverables]: yup
    .string()
    .oneOf(Object.values(ProjectDeliverablesEnum))
    .when(contextProjectStyle, {
      is: ProjectStyleEnum.DocStyle,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().test(yupTestNotDefined),
    })
    .default(null),
  [basicInfoFields.rawFootageType]: yup
    .string()
    .oneOf(Object.values(RawFootageTypeEnum))
    .when(contextProjectDeliverables, {
      is: (value: ProjectDeliverablesEnum) =>
        [
          ProjectDeliverablesEnum.RawFootageOnly,
          ProjectDeliverablesEnum.RawFootageAndHeroVideos,
        ].includes(value),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().test(yupTestNotDefined),
    })
    .default(null),
  [basicInfoFields.footageType]: yup
    .string()
    .oneOf(Object.values(FootageTypeEnum))
    .when(contextProjectStyle, {
      is: (value: ProjectStyleEnum) =>
        [ProjectStyleEnum.DocStyle, ProjectStyleEnum.Scripted].includes(value),
      then: (schema) => schema.required().default(FootageTypeEnum.BRoll),
      otherwise: (schema) => schema.notRequired().test(yupTestNotDefined),
    })
    .default(null),
  [basicInfoFields.timeline]: yup
    .string()
    .oneOf(Object.values(ProjectTimelineEnum))
    .required()
    .default(ProjectTimelineEnum.STANDARD),
  [basicInfoFields.dontUseForMarketing]: yup
    .boolean()
    .required()
    .default(false),
});

export type BasicInfoFormValues = yup.InferType<typeof basicInfoSchema>;
